.our_partners{
  margin: 15px auto 100px;
  .main_button{
    margin-top: 50px;
    margin-left: 50%;
    transform: translate(-50%,0);
  }
}
.block_item{
  display: flex;
  background: $background-white;
  box-shadow: 0px 7px 29px rgba(5, 31, 51, 0.07);
  border-radius: 5px;
  text-align: center;
  margin-top: 30px;
  transition: .35s;
  &__link{
    width: 100%;
    height: 100%;
    min-height: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover{
    transform: scale(1.1);
  }
}
.partners{
  .our_partners{
    .block_item__image{
      max-width: 80%;
    }
  }
}

// media
@media screen and (max-width: 1700px){
  .our_partners {
    margin: 70px auto 80px;
  }
}
@media screen and (max-width: 767px){
  .our_partners {
    margin: 30px auto 40px;
  }
}