/* poppins-100 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/Poppins/poppins-v5-latin-100.eot'); /* IE9 Compat Modes */
  src: local('Poppins Thin'), local('Poppins-Thin'),
  url('../fonts/Poppins/poppins-v5-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/Poppins/poppins-v5-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-100.woff') format('woff'), /* Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/Poppins/poppins-v5-latin-100.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-100italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  src: url('../fonts/Poppins/poppins-v5-latin-100italic.eot'); /* IE9 Compat Modes */
  src: local('Poppins Thin Italic'), local('Poppins-ThinItalic'),
  url('../fonts/Poppins/poppins-v5-latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/Poppins/poppins-v5-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-100italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/Poppins/poppins-v5-latin-100italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-200 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/Poppins/poppins-v5-latin-200.eot'); /* IE9 Compat Modes */
  src: local('Poppins ExtraLight'), local('Poppins-ExtraLight'),
  url('../fonts/Poppins/poppins-v5-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/Poppins/poppins-v5-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-200.woff') format('woff'), /* Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/Poppins/poppins-v5-latin-200.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-200italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  src: url('../fonts/Poppins/poppins-v5-latin-200italic.eot'); /* IE9 Compat Modes */
  src: local('Poppins ExtraLight Italic'), local('Poppins-ExtraLightItalic'),
  url('../fonts/Poppins/poppins-v5-latin-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/Poppins/poppins-v5-latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-200italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/Poppins/poppins-v5-latin-200italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-300 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/Poppins/poppins-v5-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Poppins Light'), local('Poppins-Light'),
  url('../fonts/Poppins/poppins-v5-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/Poppins/poppins-v5-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-300.woff') format('woff'), /* Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/Poppins/poppins-v5-latin-300.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-300italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/Poppins/poppins-v5-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local('Poppins Light Italic'), local('Poppins-LightItalic'),
  url('../fonts/Poppins/poppins-v5-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/Poppins/poppins-v5-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-300italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/Poppins/poppins-v5-latin-300italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Poppins/poppins-v5-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Poppins Regular'), local('Poppins-Regular'),
  url('../fonts/Poppins/poppins-v5-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/Poppins/poppins-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/Poppins/poppins-v5-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/Poppins/poppins-v5-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Poppins Italic'), local('Poppins-Italic'),
  url('../fonts/Poppins/poppins-v5-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/Poppins/poppins-v5-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/Poppins/poppins-v5-latin-italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-500 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/Poppins/poppins-v5-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Poppins Medium'), local('Poppins-Medium'),
  url('../fonts/Poppins/poppins-v5-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/Poppins/poppins-v5-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-500.woff') format('woff'), /* Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/Poppins/poppins-v5-latin-500.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-500italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/Poppins/poppins-v5-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local('Poppins Medium Italic'), local('Poppins-MediumItalic'),
  url('../fonts/Poppins/poppins-v5-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/Poppins/poppins-v5-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-500italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/Poppins/poppins-v5-latin-500italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-600 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/Poppins/poppins-v5-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
  url('../fonts/Poppins/poppins-v5-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/Poppins/poppins-v5-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-600.woff') format('woff'), /* Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/Poppins/poppins-v5-latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-600italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/Poppins/poppins-v5-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local('Poppins SemiBold Italic'), local('Poppins-SemiBoldItalic'),
  url('../fonts/Poppins/poppins-v5-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/Poppins/poppins-v5-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-600italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/Poppins/poppins-v5-latin-600italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-700 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Poppins/poppins-v5-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Poppins Bold'), local('Poppins-Bold'),
  url('../fonts/Poppins/poppins-v5-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/Poppins/poppins-v5-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/Poppins/poppins-v5-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-700italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/Poppins/poppins-v5-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Poppins Bold Italic'), local('Poppins-BoldItalic'),
  url('../fonts/Poppins/poppins-v5-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/Poppins/poppins-v5-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-700italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/Poppins/poppins-v5-latin-700italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-800 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/Poppins/poppins-v5-latin-800.eot'); /* IE9 Compat Modes */
  src: local('Poppins ExtraBold'), local('Poppins-ExtraBold'),
  url('../fonts/Poppins/poppins-v5-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/Poppins/poppins-v5-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-800.woff') format('woff'), /* Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/Poppins/poppins-v5-latin-800.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-800italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  src: url('../fonts/Poppins/poppins-v5-latin-800italic.eot'); /* IE9 Compat Modes */
  src: local('Poppins ExtraBold Italic'), local('Poppins-ExtraBoldItalic'),
  url('../fonts/Poppins/poppins-v5-latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/Poppins/poppins-v5-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-800italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/Poppins/poppins-v5-latin-800italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-900 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/Poppins/poppins-v5-latin-900.eot'); /* IE9 Compat Modes */
  src: local('Poppins Black'), local('Poppins-Black'),
  url('../fonts/Poppins/poppins-v5-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/Poppins/poppins-v5-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-900.woff') format('woff'), /* Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/Poppins/poppins-v5-latin-900.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-900italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  src: url('../fonts/Poppins/poppins-v5-latin-900italic.eot'); /* IE9 Compat Modes */
  src: local('Poppins Black Italic'), local('Poppins-BlackItalic'),
  url('../fonts/Poppins/poppins-v5-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/Poppins/poppins-v5-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-900italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/Poppins/poppins-v5-latin-900italic.svg#Poppins') format('svg'); /* Legacy iOS */
}