.company{
  .block_item{
    height: 150px;
    img{
      max-width: 100%;
    }
    &__link{
      min-height: 100%;
      padding: 0 30px;
    }
  }
}

// media
@media screen and (max-width: 1024px){
  .company{
    .block_item{
      height: 130px;
      img{
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 767px){
  .company{
    .block_item{
      height: 100px;
      margin-top: 10px;
      &__link{
        padding: 0 60px;
      }
    }
    .main_title{
      margin-bottom: 15px;
    }
  }
}