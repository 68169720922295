.what_we_looking{
  background: $background-light-grey;
  margin-top: 130px;
  padding-top: 30px;
  padding-bottom: 230px;
  position: relative;
  &:before{
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    top: -130px;
    width: 100%;
    height: 130px;
    background: url("/img/looking-top.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }
  &:after{
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 130px;
    background: url("/img/looking-bottom.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }
  &__title{
    margin-bottom: 45px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-size: 36px;
    text-align: center;
    color: $text-color-bold;
  }
  .item{
    background: $background-white;
    box-shadow: 0px 7px 29px rgba(5, 31, 51, 0.07);
    border-radius: 5px;
    padding: 40px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 260px;
    margin-bottom: 30px;
    transition: .35s;
    &:hover{
      transform: scale(1.1);
    }
    &__title{
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      font-size: 20px;
      text-align: center;
      color: $text-color-bold;
      margin: 15px auto 20px;
    }
    &__description{
      font-style: normal;
      font-weight: 300;
      line-height: 27px;
      font-size: 15px;
      text-align: center;
      color: $text-color-light;
      max-width: 100%;
    }
  }
  .main_button{
    margin-top: 40px;
    margin-left: 50%;
    transform: translate(-50%);
  }
}

// media
@media screen and (max-width: 1200px){
  .what_we_looking{
    .item{
      min-height: 285px;
    }
  }
}
@media screen and (max-width: 1024px){
  .what_we_looking{
    margin-top: 0;
    padding-top: 70px;
    padding-bottom: 80px;
    &:before{
      content: none;
    }
    &:after{
      content: none;
    }
    &__title{
      font-size: 26px;
    }
  }
}
@media screen and (max-width: 991px){
  .what_we_looking{
    .item{
      &__title{
        font-size: 18px;
        margin: 20px auto 15px;
      }
      &__description{
        line-height: 27px;
        font-size: 15px;
      }
    }
    .main_button{
      margin-top: 20px;
    }
  }
}
@media screen and (max-width: 767px){
  .what_we_looking{
    padding-top: 35px;
    padding-bottom: 40px;
    &__title{
      font-size: 20px;
      margin-bottom: 20px;
    }
    .item{
      padding: 30px;
      min-height: max-content;
      margin-bottom: 10px;
      &__title{
        margin: 15px auto 15px;
      }
      &__description{
        line-height: 23px;
        font-size: 13px;
      }
    }
    .main_button{
      margin-top: 20px;
    }
  }
}