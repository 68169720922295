.careers{
  .main_banner_title{
    margin-bottom: 0;
  }
  .main_banner_sub_title{
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-size: 20px;
    margin: 20px 0 35px;
    color: $text-color-light;
    .mark_text{
      color: $text-color-blue;
    }
  }
}



// media
@media screen and (max-width: 767px){
  .careers{
    .main_banner_sub_title{
      font-size: 18px;
    }
    .main_button{
      margin-bottom: 45px;
    }
  }
}