.map{
  &.overflow{
    overflow: hidden;
  }
  width: 100%;
  padding: 140px 0 75px;
  position: relative;
  background-color: $background-light-grey;
  .main_title_wrap{
    position: absolute;
    left: 50%;
    top: 200px;
    transform: translate(-50%,0);
    .main_title{
      max-width: 750px;
      margin: 0 auto;
    }
  }
  &:before{
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 140px;
    background: url("../../img/vector-map-top.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  &:after{
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 140px;
    background: url("../../img/vector-map-bottom.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  path{
    &.land{
      transition: .35s;
      &:hover{
        opacity: 0.7;
      }
    }
  }
  .mobile_content{
    display: none;
  }
  .map_desktop{
    height: 100%;
  }
  .map_tablet{
    height: 100%;
  }
}
.map_modal{
  width: 220px;
  background: $background-white;
  box-shadow: 0px 13px 50px rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  transition: transform .35s, opacity .35s;
  opacity: 0;
  transform: scale(0);
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  &.open{
    display: block;
    transform: scale(1);
    opacity: 1;
  }
  &__close{
    display: none;
    position: absolute;
    right: 12px;
    top: 6px;
  }
  &__country{
    padding: 30px;
    border-bottom: 1px solid $border-color;
    display: flex;
    align-items: center;
    .text{
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      font-size: 20px;
      color: $text-color-bold;
      margin-right: 10px;
    }
  }
  &__platforms{
    padding: 20px 30px;
    border-bottom: 1px solid $border-color;
    .text{
      font-style: normal;
      font-weight: 300;
      line-height: 27px;
      font-size: 15px;
      color: $text-color-bold;
    }
    .images{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .images_item{
        margin-top: 15px;
      }
    }
  }
  &__traffic{
    padding: 20px 30px;
    font-style: normal;
    font-weight: 300;
    line-height: 27px;
    font-size: 15px;
    color: $text-color-bold;
    .numbers{
      font-weight: 500;
    }
  }
}
.map_tablet{
  display: none;
}

// accordion
.card{
  border: none;
  margin-bottom: 10px;
  &.open{
    .card-header{
      border-radius: 5px 5px 0 0;
    }
  }
  .card-header{
    border-radius: 5px;
    padding: 20px 30px;
    background: $background-white;
    box-shadow: 0px 13px 50px rgba(0, 0, 0, 0.04);
    border-bottom: 1px solid $border-color;
    &__title{
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      font-size: 18px;
      color: $text-color-bold;
      img{
        margin-left: 10px;
      }
    }
    .btn{
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;
      padding: 0;
    }
    .arrow{
      transition: .35s;
      &.open{
        transform: rotate(180deg);
      }
    }
  }
  .card-body{
    background-color: $background-white;
    padding: 15px 30px 20px;
    border-bottom: 1px solid $border-color;
    &__title{
      font-style: normal;
      font-weight: 300;
      line-height: 23px;
      font-size: 13px;
      color: $text-color-bold;
    }
    .images_item{
      margin-top: 20px;
    }
  }
  .card-footer{
    border-radius: 0 0 5px 5px;
    border-top: none;
    padding: 15px 30px 20px;
    background: $background-white;
    &__text{
      font-style: normal;
      font-weight: 300;
      line-height: 23px;
      font-size: 13px;
      color: $text-color-bold;
      .numbers{
        font-weight: 500;
      }
    }
  }
}

//media
@media screen and (max-width: 1700px){
  .map_desktop{
    display: none;
  }
  .map_tablet{
    display: block;
  }
  .map{
    padding: 0;
    &:before{
      content: none;
    }
    &:after{
      content: none;
    }
  }
}
@media screen and (max-width: 1024px){
.map_modal{
  &__close{
    display: block;
  }
}
}
@media screen and (max-width: 767px){
  .map{
    padding: 30px 0 35px 0;
    .main_title_wrap{
      position: relative;
      left: 0;
      top: 0;
      transform: translate(0);
      .main_title{
        margin-bottom: 20px;
      }
    }
    svg{
      display: none;
    }
    .mobile_content{
      display: block;
      svg{
        display: block;
      }
    }
  }
}

// ie
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .map{
    .map_desktop{
      height: 850px;
    }
    .map_tablet{
      height: 800px;
    }
  }
}