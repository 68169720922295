@import "~select2/dist/css/select2.min.css";

.contact_form{
  padding-top: 90px;
  padding-bottom: 85px;
  &__title{
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
    font-size: 18px;
    text-align: center;
    color: $text-color-light;
    margin-bottom: 45px;
  }
  .form{
    max-width: 570px;
    margin: 0 auto;
    .input_wrap{
      margin-bottom: 20px;
      input, textarea, select, .select2-selection{
        width: 100%;
        height: auto;
        background: $background-white;
        border: 1px solid $form-color;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 27px;
        font-size: 15px;
        color: $form-color;
        outline: none;
        &::placeholder{
          font-style: normal;
          font-weight: 500;
          line-height: 27px;
          font-size: 15px;
          color: $form-color;
        }
      }
      .select2-container{
        width: 100%!important;
      }
      .select2-selection__arrow{
        top: 50%;
        right: 20px;
        width: 12px;
        height: 7px;
        transform: translate(0,-50%);
        transition: .35s;
        background: url("/img/arrow.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        b{
          opacity: 0;
        }
      }
      .select2-container--open{
        .select2-selection__arrow{
          transform: rotate(-180deg);
        }
      }
      textarea{
        resize: none;
        height: 110px;
      }
      &:last-child{
        margin-bottom: 0;
      }
      &.submit{
        text-align: center;
        input{
          background: $background-yellow;
          max-width: 200px;
          font-style: normal;
          font-weight: normal;
          line-height: normal;
          font-size: 16px;
          text-align: center;
          color: $text-color-bold;
          border: none;
          border-radius: 3px;
          transition: .35s;
          cursor: pointer;
          &:hover{
            background: $background-yellow-hover;
          }
        }
      }
    }
    .mail_status{
      display: none;
      margin-top: 20px;
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      font-size: 16px;
      text-align: center;
      color: $text-color-bold;
      &.show{
        display: block;
      }
    }
  }
}
.select2-search{
  display: none;
}

// media
@media screen and (max-width: 1024px){
  .contact_form{
    padding-top: 60px;
    padding-bottom: 50px;
    &__title{
      margin-bottom: 30px;
      br{
        display: none;
      }
    }
    .form {
      .input_wrap {
        input, textarea, select, .select2-selection {
          padding: 15px 20px;
        }
      }
    }
  }
}
@media screen and (max-width: 767px){
  .contact_form{
    padding-top: 40px;
    padding-bottom: 35px;
    &__title{
      font-size: 13px;
      line-height: 23px;
      margin-bottom: 15px;
    }
  }
}