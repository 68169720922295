.technology, .company {
  .mobile_content {
    display: none;
  }
}
.nav-tabs{
  border: none;
  display: flex;
  justify-content: center;
  .nav-link{
    border: none;
    padding: 20px 45px 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    font-size: 18px;
    text-align: center;
    color: $text-color-light;
    &.active{
      border-bottom: 4px solid $background-yellow;
    }
  }
}
.tab-content{
  width: 100%;
  .mobile_content{
    display: none;
  }
  .tabs_map{
    padding: 70px 0 220px;
    //background-image: url("../../img/tabs-map.svg");
    background-image: url("../../img/tabs-map-world.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    &.visame, &.credilo{
      background-image: url("../../img/tabs-map-world-3-country.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    &.gutrate, &.creditsme{
      background-image: url("../../img/tabs-map-world-russia.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    &:after{
      content: "";
      display: inline-block;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 140px;
      width: 100%;
      background: url("../../img/tabs-map-bottom.svg");
      background-repeat: no-repeat;
      background-size: cover;
    }
    &.mazilla{

    }
    &__title{
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      font-size: 36px;
      text-align: center;
      color: $text-color-bold;
    }
    &__sub_title{
      max-width: 750px;
      margin: 15px auto 50px;
      font-style: normal;
      font-weight: 300;
      line-height: 32px;
      font-size: 18px;
      text-align: center;
      color: $text-color-light;
    }
    &__description{
      max-width: 400px;
      background: $background-white;
      box-shadow: 0px 13px 50px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      .description_block{
        padding: 30px;
        border-bottom: 1px solid $border-color;
        &__title{
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          font-size: 20px;
          color: $text-color-bold;
        }
        &__sub_title{
          font-style: normal;
          font-weight: 300;
          line-height: 23px;
          font-size: 13px;
          color: $text-color-light;
        }
        &:last-child{
          border-bottom: none;
        }
      }
    }
  }
}

// media
@media screen and (max-width: 1199px) {
  .company {
    .nav-tabs {
      justify-content: space-between;
      .nav-link {
        font-size: 18px;
        padding: 20px 15px 15px;
      }
    }
  }
}
@media screen and (max-width: 1024px){
  .nav-tabs {
    .nav-link {
      font-size: 16px;
      padding: 20px 35px 15px;
    }
  }
  .description_block__title{
    font-size: 18px;
    .numbers{
      display: block;
    }
  }
  .tab-content .tabs_map{
    &__title{
      font-size: 26px;
    }
    &__sub_title{
      font-size: 15px;
      line-height: 27px;
      margin-bottom: 240px;
    }
    &:after{
      content: none;
    }
  }
  .tab-content .tabs_map__description{
    max-width: 100%;
    display: flex;
  }
  .tab-content .tabs_map{
    background-image: url("../../img/tabs-map-world-tablet.svg");
    background-position: 50% 110%;
    padding: 40px 0;
    &.visame, &.credilo{
      background-image: url("../../img/tabs-map-world-tablet-3-country.svg");
      background-position: 50% 110%;
    }
    &.gutrate, &.creditsme{
      background-image: url("../../img/tabs-map-world-tablet-russia.svg");
      background-position: 50% 110%;
    }
  }
}
@media screen and (max-width: 767px){
  .technology, .company {
    .mobile_content {
      display: flex;
    }
    .desktop_content{
      display: none;
    }
  }
  .technology .mobile_nav{
    display: block;
    position: relative;
    top: -35px;
  }
  .tab-content .tabs_map{
    background: #fff!important;
    padding-top: 0;
    background-image: none;
    .mobile_content{
      display: block;
    }
    &__title{
      font-size: 20px;
    }
    &__sub_title{
      margin-bottom: 15px;
    }
    &__description{
      flex-direction: column;
      margin-top: 10px;
      .numbers{
        display: inline-block;
      }
      .description_block{
        &__title{
          font-size: 18px;
          margin-bottom: 15px;
        }
      }
    }
  }
.technology, .company {
  .card-header {
    border-radius: 5px;
    padding: 20px 30px;
    background: $background-white;
    box-shadow: 0px 13px 50px rgba(0, 0, 0, 0.04);
    border-bottom: 1px solid $border-color;
    margin-bottom: 10px;
    &__title {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      font-size: 18px;
      color: $text-color-bold;
      img {
        margin-left: 10px;
      }
    }
    .btn {
      display: flex;
      justify-content: space-between;
      text-decoration: none;
      padding: 0;
    }
  }
  .card-body{
    box-shadow: 0 13px 50px rgba(0,0,0,.04);
    border-radius: 5px;
    .nav-tabs{
      flex-direction: column;
    }
  }
}
}

// graphics
.company{
  .tab-content{
    position: relative;
    .tab-pane{
      display: block!important;
      position: absolute;
      width: 100%;
      transform: scale(0);
    }
  }
  .tab-content{
    .tab-pane{
      &.active{
        position: relative;
        transform: scale(1);
      }
    }
  }
}
