.documents {
  padding-bottom: 90px;
  .card {
    box-shadow: 0 7px 29px rgba(5, 31, 51, .07);
    .card-header {
      box-shadow: none;
      border-bottom: none;
      padding: 30px 50px;
      &__title {
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        font-size: 36px;
        color: $text-color-bold;
      }
    }
    .card-body {
      padding: 0 50px 30px;
      .document{
        display: flex;
        margin-bottom: 30px;
        &__icon{
          margin-right: 20px;
        }
        &__description_link{
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          font-size: 15px;
          a{
            color: $text-color-bold;
            transition: .35s;
            &:hover{
              color: #3E80E4;
            }
          }
        }
        &__description_size{
          font-style: normal;
          font-weight: 300;
          line-height: 23px;
          font-size: 13px;
          color: $text-color-light;
        }
      }
    }
  }
}

// media
@media screen and (max-width: 1024px){
  .documents{
    padding-bottom: 75px;
    .card {
      .card-header {
        padding: 30px 30px;
        &__title {
          line-height: normal;
          font-size: 26px;
        }
      }
      .card-body {
        padding: 0 30px 20px;
        .document{
          &__icon{
            img{
              max-width: 40px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px){
  .documents{
    padding-bottom: 40px;
    .card{
      .card-header{
        padding: 20px 20px;
        &__title{
          font-size: 18px;
        }
      }
      .card-body{
        padding: 0 20px 30px;
        .document{
          margin-bottom: 10px;
          &__icon{
            margin-right: 15px;
            img{
              max-width: 30px;
            }
          }
          &__description_link{
            font-weight: 300;
            font-size: 13px;
          }
          &__description_size{
            font-size: 11px;
          }
        }
      }
    }
  }
}