.members{
  position: relative;
  margin-top: 130px;
  padding-top: 30px;
  padding-bottom: 190px;
  background-color: $background-light-grey;
  &:before{
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    top: -130px;
    height: 130px;
    width: 100%;
    background: url("../img/members-top.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }
  &:after{
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 130px;
    width: 100%;
    background: url("../img/members-bottom.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }
  &__title{
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-size: 36px;
    text-align: center;
    margin-bottom: 100px;
  }
  &__item{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 75px;
    .item{
      &__image{
        position: relative;
        margin-right: 30px;
        img{
          position: relative;
          z-index: 1;
        }
        &:before{
          content: "";
          display: inline-block;
          position: absolute;
          width: 70px;
          height: 70px;
          border-radius: 50%;
          right: -10px;
          top: -15px;
          background: $background-yellow;
        }
        &:after{
          content: "";
          display: inline-block;
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          left: 15px;
          bottom: -5px;
          background: $background-yellow;
        }
      }
      &__description_title{
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        font-size: 20px;
        color: $text-color-bold;
      }
      &__description_sub_title{
        font-style: normal;
        font-weight: 300;
        line-height: 27px;
        font-size: 15px;
        color: $text-color-light;
      }
    }
  }
}

// media
@media screen and (max-width: 1024px){
.members{
  margin-top: 0;
  padding-top: 70px;
  padding-bottom: 10px;
  &:before{
    content: none;
  }
  &:after{
    content: none;
  }
  &__title{
    font-size: 26px;
  }
  &__item{
    .item{
      &__description_title{
        font-size: 18px;
      }
      &__description_sub_title{
        line-height: 27px;
        font-size: 15px;
      }
    }
  }
}
}
@media screen and (max-width: 767px){
  .members{
    padding-top: 40px;
    padding-bottom: 40px;
    &__title{
      line-height: normal;
      font-size: 20px;
      margin-bottom: 0;
    }
    &__item{
      margin-bottom: 0;
      margin-top: 30px;
      flex-direction: column;
      justify-content: center;
      .item{
        &__image{
          margin-right: 0;
        }
        &__description{
          text-align: center;
          margin-top: 20px;
        }
      }
    }
    .slick-arrow{
      position: absolute;
      top: 50%;
      transform: translate(0,-50%);
      z-index: 1;
      &:hover{
        svg{
          fill:#3E80E4;
        }
      }
      svg{
        fill:#42474B;
      }
    }
    .slick-prev{
      left: 0;
    }
    .slick-next{
      right: 0;
    }
  }
}