.company_information{
  .main_banner_title{
    margin-bottom: 0;
  }
  .main_banner_sub_title{
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
    font-size: 18px;
    margin: 20px 0 35px;
    color: $text-color-light;
  }
}



// media
@media screen and (max-width: 1024px){
  .company_information{
    .main_banner_title{
      br{
        display: none;
      }
    }
    .main_banner_sub_title{
      font-size: 15px;
    }
  }
}
@media screen and (max-width: 767px){
  .company_information{
    .main_banner_title{
      margin-bottom: 45px;
      br{
        display: block;
      }
    }
    .main_banner_sub_title{
      display: none;
    }
  }
}