.accordion{
  padding-top: 90px;
  padding-bottom: 100px;
  &__title{
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-size: 36px;
    text-align: center;
    color: $text-color-bold;
  }
  &__sub_title{
    margin-bottom: 40px;
    margin-top: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
    font-size: 18px;
    text-align: center;
    color: $text-color-light;
  }
  .card{
    box-shadow: 0 7px 29px rgba(5,31,51,.07);
    .card-header{
      box-shadow: none;
      border-bottom: none;
      padding: 30px 50px;
      &__title{
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        font-size: 36px;
        color: $text-color-bold;
      }
    }
    .card-body{
      padding: 0 50px 30px;
      .list_title{
        margin-bottom: 25px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        font-size: 20px;
        color: $text-color-light;
      }
      .list_block{
        margin-bottom: 30px;
        &:last-child{
        margin-bottom: 0;
      }
      }
      .list_content{
        ul{
          margin: 0;
          padding: 0 0 0 0;
          list-style: none;
          li{
            font-style: normal;
            font-weight: 300;
            line-height: 27px;
            font-size: 15px;
            margin-left: 15px;
            color: $text-color-light;
            position: relative;
            &:before{
              content: "";
              display: inline-block;
              position: absolute;
              margin-bottom: 3px;
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background: $text-color-blue;
              left: -15px;
              top: 11px;
            }
          }
        }
      }
    }
  }
}

// media
@media screen and (max-width: 1024px){
  .accordion{
    padding-top: 70px;
    padding-bottom: 80px;
    &__title{
      line-height: normal;
      font-size: 26px;
    }
    &__sub_title{
      line-height: 27px;
      font-size: 15px;
    }
    .card{
      .card-header{
        padding: 30px 30px;
        &__title{
          line-height: normal;
          font-size: 26px;
        }
      }
      .card-body{
        padding: 0 30px 30px;
        .list_title{
          line-height: normal;
          font-size: 18px;
        }
        .list_block{
        }
        .list_content{
          ul{
            li{
              line-height: 27px;
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px){
  .accordion{
    padding-top: 40px;
    padding-bottom: 40px;
    &__title{
      font-size: 20px;
    }
    &__sub_title{
      margin-top: 10px;
      margin-bottom: 15px;
      br{
        display: none;
      }
    }
    .card{
      .card-header{
        padding: 20px 20px;
        &__title{
          font-size: 18px;
          max-width: 200px;
          white-space: normal;
          text-align: left;
        }
      }
      .card-body{
        padding: 0 20px 30px;
        .list_content{
          ul{
            li{
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}