.our_creed{
  .mobile_content{
    display: none;
  }
  padding-top: 90px;
  padding-bottom: 130px;
  &__title{
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-size: 36px;
    text-align: center;
    color: $text-color-bold;
  }
  &__sub_title{
    max-width: 750px;
    margin: 15px auto 45px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
    font-size: 18px;
    text-align: center;
    color: $text-color-light;
  }
  &__item{
    img{
      width: 100%;
    }
    .title{
      margin-bottom: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      font-size: 20px;
      text-align: center;
      color: $text-color-bold;
      position: relative;
      &.line_right{
        &:after{
          content: "";
          display: inline-block;
          position: absolute;
          width: 120px;
          height: 1px;
          background: #EBF0F4;
          top: 50%;
          right: -20px;
          transform: translate(0,-50%);
        }
      }
      &.line_left{
        &:after{
          content: "";
          display: inline-block;
          position: absolute;
          width: 120px;
          height: 1px;
          background: #EBF0F4;
          top: 50%;
          left: -20px;
          transform: translate(0,-50%);
        }
      }
    }
    .description{
      font-style: normal;
      font-weight: 300;
      line-height: 27px;
      font-size: 15px;
      text-align: center;
      color: $text-color-light;
    }
    &.mt_100{
      margin-top: 100px;
    }
    &.mt_30{
      margin-top: 30px;
    }
  }
}

// media
@media screen and (max-width: 1199px) {
  .our_creed {
    &__item {
      .title {
        &.line_right {
          &:after {
            width: 90px;
          }
        }
        &.line_left {
          &:after {
            width: 90px;
          }
        }
      }
      &.mt_100 {
        margin-top: 75px;
      }
      &.mt_30 {
        margin-top: -30px;
      }
    }
  }
}
@media screen and (max-width: 1024px){
  .our_creed{
    .desktop_content{
      display: none;
    }
    .mobile_content{
      display: block;
    }
    padding-top: 70px;
    padding-bottom: 50px;
    &__title{
      font-size: 26px;
    }
    &__sub_title{
      line-height: 27px;
      font-size: 15px;
    }
    &__item{
      display: flex;
      margin-bottom: 20px;
      .image{
        margin-right: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
          width: 90px;
        }
        &:after{
          content: "";
          display: block;
          margin-top: 15px;
          width: 1px;
          height: 100%;
          background: #EBF0F4;
        }
      }
      .content{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .title{
          font-size: 18px;
        }
        .description{
          text-align: left;
        }
      }
    }
  }
}
@media screen and (max-width: 767px){
  .our_creed{
    padding-top: 40px;
    padding-bottom: 40px;
    &__title{
      font-size: 20px;
    }
    &__sub_title{
      margin: 15px auto 30px;
      line-height: 27px;
      font-size: 15px;
    }
    &__item{
      .image{
        margin-right: 15px;
        img{
          width: 60px;
        }
      }
      .content{
        .description{
          line-height: 23px;
          font-size: 13px;
        }
      }
    }
  }
}