$font-stack: 'Poppins', sans-serif;
$text-color-light: #42474B;
$text-color-bold: #051F33;
$text-color-blue: #007bff;
$background-white: #FFFFFF;
$background-yellow: #FFD800;
$background-light-grey: #F7F9FB;
$background-grey: #EBEDEF;
$background-yellow-hover: #FFE86B;
$border-color: #EDF0F3;
$form-color: #42474B;
$button-height: 20px;
$button-width: 20px;