@import "~slick-carousel/slick/slick.css";

.slider_wrap{
  padding: 90px 15px;
  .slider{
    margin: 0 0 65px;
    .main_title{
      margin-bottom: 75px;
    }
    .slick-dots{
      padding: 0;
      margin: 0;
      list-style: none;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0,-50%);
      width: 50px;
      height: 100px;
      background: $background-light-grey;
      border-radius: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      li button{
        font-size: 0;
        line-height: 0;
        display: block;
        width: 10px;
        height: 12px;
        border-radius: 50%;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: none;
        transition: .35s;
        &:hover{
          opacity: 0.7;
          background: $background-yellow-hover;
        }
      }
      li.slick-active button{
        background: $background-yellow;
      }
      li:nth-child(2){
        margin: 15px 0;
      }
    }
    .four_elem{
      .slider_item__image img{
        min-height: 70px;
      }
    }
  }
  .slider_item{
    padding: 0 15px;
    &__image{
      img{
        margin: 0 auto;
      }
    }
    &__title{
      margin: 24px 0;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      font-size: 45px;
      text-align: center;
      color: $text-color-bold;
      .small_words{
        font-size: 20px;
        display: block;
      }
    }
    &__description{
      font-style: normal;
      font-weight: 300;
      line-height: 27px;
      font-size: 15px;
      text-align: center;
      color: $text-color-light;
    }
  }
  // slider_item_platforms
  .platforms{
    padding: 15px 30px;
  }
  .slider_item_platforms{
    margin-bottom: 30px;
    &__link{
      position: relative;
      background: $background-white;
      box-shadow: 0px 7px 29px rgba(5, 31, 51, 0.07);
      border-radius: 5px;
      display: flex;
      padding: 60px 70px;
      height: 150px;
      align-items: center;
      justify-content: center;
      transition: .35s;
      &:hover{
        z-index: 1;
        transform: scale(1.1);
      }
    }
    &__image{
      width: 100%;
      height: 100%;
    }
  }
}

// media
@media screen and (max-width: 991px){
  .slider_wrap{
    .slider .slick-dots{
      right: calc(50% - 50px);
      top: 100%;
      transform: translate(-50%) rotate(-90deg);
    }
    .slider_item_platforms{
      margin-bottom: 30px;
      &__link{
        padding: 30px;
        height: 130px;
      }
    }
    // four_elem
    .slide.four_elem{
      .slider_item{
        display: flex;
        margin-bottom: 40px;
        &__title{
          margin: 0 0 10px 0;
          text-align: left;
        }
        &__description{
          text-align: left;
        }
        &__image img{
          max-width: 50px;
          margin-right: 15px;
        }
      }
      .slider_item__image img{
        min-height: auto;
      }
    }
  }
}
@media screen and (max-width: 767px){
  .slider_wrap{
    padding: 30px 15px 120px;
    .slider{
      margin: 0;
      .platforms, .four_elem{
        .main_title{
          margin-bottom: 45px;
        }
      }
      .main_title{
        margin-bottom: 15px;
      }
      .slick-dots{
        right: calc(50% - 50px);
        top: 100%;
        transform: translate(-50%) rotate(-90deg);
      }
    }
    .slider_item{
      &__image{
        margin-right: 15px;
        img{
          max-width: 50px;
        }
      }
      &__title{
        font-size: 28px;
        text-align: left;
        .small_words{
          font-size: 18px;
        }
      }
      &__description{
        text-align: left;
        font-size: 13px;
        line-height: 23px;
      }
    }
    .slider_item_head {
      display: flex;
      align-items: center;
    }
    .slide{
      padding: 0;
      min-height: 760px;
      }
    // four_elem
    .four_elem{
      .slider_item{
        margin-bottom: 20px;
      }
    }
    }
    // slider_item_platforms
    .slider_wrap{
      .platforms{
        padding: 15px 30px;
      }
      .slider_item_platforms{
        margin-bottom: 30px;
        &__link{
          padding: 40px 60px;
          height: 100px;
        }
        &__image{
          max-width: 150px;
        }
      }
    }
  }

// ie
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .slider_wrap .slider .slick-dots li button{
    height: 15px;
  }
}