footer{
  background-color: $background-grey;
  padding: 60px 0;
}
.footer_menu{
  margin-bottom: 50px;
  ul{
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    a{
      display: inline-block;
      font-style: normal;
      position: relative;
      font-weight: 600;
      line-height: 26px;
      font-size: 16px;
      color: $text-color-light;
      text-decoration: none;
      &:hover:after,
      &:focus:after,
      &:active:after,
      &.active:after {
        width: 100%;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;
        width: 0%;
        transform: translateX(-50%);
        height: 4px;
        background: $background-yellow;
        transition: .35s;
      }
    }
    li{
      .number{
        display: none;
        background-color: $background-yellow;
        border-radius: 50%;
        padding: 1px 5px;
        font-weight: 600;
        margin-left: 5px;
        font-size: 12px;
        position: relative;
        top: -2px;
      }
    }
  }
}
.copyright_wrap{
  display: flex;
  align-items: center;
  .copyright{
    margin-left: 55px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    font-size: 14px;
    color: $text-color-light;
  }
}

// media
@media screen and (max-width: 767px){
  .footer_menu ul{
    flex-direction: column;
    li{
      margin-bottom: 15px;
    }
    a{
      line-height: 23px;
      font-size: 14px;
    }
  }
  .copyright_wrap{
    flex-direction: column;
    .copyright{
      margin-left: 0;
      margin-top: 20px;
      text-align: center;
    }
  }
}