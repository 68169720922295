.people{
  .members{
    margin-top: 0;
    padding-bottom: 100px;
    background: $background-white;
    .mobile_content{
      display: none;
    }
    &:before{
      content: none;
    }
    &:after{
      content: none;
    }
    &__item{
      img{
        border-radius: 50%;
        max-width: 100px;
      }
    }
  }
}

// media
@media screen and (max-width: 1024px){
  .people {
    .members {
      margin-top: 0;
      padding-top: 70px;
      padding-bottom: 100px;
    }
  }
}
@media screen and (max-width: 767px){
  .people{
    .members{
      padding-top: 40px;
      padding-bottom: 40px;
      .desktop_content{
        display: none;
      }
      .mobile_content{
        display: block;
      }
    }
  }
}