.information{
  padding-bottom: 160px;
  .information_description{
    &__title{
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      font-size: 36px;
      color: $text-color-bold;
    }
    &__sub_title{
      margin: 25px 0;
      font-style: normal;
      font-weight: 300;
      line-height: 32px;
      font-size: 18px;
      color: $text-color-light;
    }
  }
  .information_image{
    img{
      max-width: 100%;
    }
  }
}

// media
@media screen and (max-width: 1024px){
  .information{
    padding-top: 80px;
    padding-bottom: 80px;
    .information_description{
      &__title{
        font-size: 26px;
      }
      &__sub_title{
        line-height: 27px;
        font-size: 15px;
      }
    }
    .information_image{
      img{
        max-width: 100%;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .information {
    padding-top: 40px;
    padding-bottom: 40px;
    .information_description {
      &__title {
        font-size: 20px;
      }
      &__sub_title{
        margin: 20px 0;
      }
    }
    .information_image {
      margin-bottom: 20px;
    }
  }
}
