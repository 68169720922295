.main_banner_bg{
  //height: 100vh;
  padding: 140px 0;
  background-image: url("../../img/header-bg.svg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  a{
    pointer-events: auto;
    position: relative;
    z-index: 1;
  }
}
.main_banner_title{
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 45px;
  color: $text-color-bold;
  margin-bottom: 45px;
  .typed{
    display: block;
  }
}
.main_banner_image{
  text-align: center;
}
.main_button{
  pointer-events: auto;
  background-color: $background-yellow;
  border-radius: 3px;
  display: inline-block;
  transition: all .35s;
  .main_button_link{
    text-decoration: none;
    padding: 19px 21px;
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 16px;
    text-align: center;
    color: $text-color-bold;
  }
  &:hover{
    background-color: $background-yellow-hover;
  }
}

// media
@media screen and (max-width: 1024px){
  .main_banner_bg{
    padding: 150px 0 70px;
  }
  .main_banner_image{
    img{
      max-width: 265px;
    }
  }
}
@media screen and (max-width: 991px){
  .main_banner_title{
    font-size: 36px;
  }
}
@media screen and (max-width: 767px){
  .main_banner_title{
    font-size: 28px;
  }
  .main_banner_image{
    display: none;
  }
  .main_banner_bg{
    background: $background-light-grey;
    padding-bottom: 0;
  }
  .main_button{
    width: 100%;
    text-align: center;
  }
  .home_page{
    .main_button{
      margin-bottom: 45px;
    }
  }
}