header{
  position: absolute;
  width: 100%;
  top: 40px;
}
.logo_link{
  position: relative;
  z-index: 101;
}
.desktop_menu{
  width: 100%;
  ul{
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    a{
      display: inline-block;
      position: relative;
      text-decoration: none;
      line-height: 23px;
      font-size: 14px;
      color: $text-color-light;
      &:hover:after,
      &:focus:after,
      &:active:after,
      &.active:after {
        width: 100%;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;
        width: 0%;
        transform: translateX(-50%);
        height: 4px;
        background: $background-yellow;
        transition: .35s;
      }
    }
    li{
      .number{
        display: none;
        background-color: $background-yellow;
        border-radius: 50%;
        padding: 1px 5px;
        font-weight: 600;
        margin-left: 5px;
        font-size: 12px;
      }
    }
  }
}
// burger
.mobile_menu{
  &__title{
    font-weight: 500;
    line-height: 29px;
    font-size: 16px;
    color: $text-color-bold;
    margin-right: 10px;
  }
}
.button_container {
  position: relative;
  top: 5%;
  right: 2%;
  height: $button-height;
  width: $button-width;
  cursor: pointer;
  z-index: 101;
  transition: opacity .25s ease;

  &:hover {
    opacity: .7;
  }

  &.active {
    .top {
      transform: translateY(8px) translateX(0) rotate(45deg);
      background: $text-color-light;
    }
    .middle {
      opacity: 0;
      background: $text-color-light;
    }

    .bottom {
      transform: translateY(-8px) translateX(0) rotate(-45deg);
      background: $text-color-light;
    }
  }

  span {
    background: $text-color-bold;
    border: none;
    height: 3px;
    border-radius: 3px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition:  all .35s ease;
    cursor: pointer;

    &:nth-of-type(2) {
      top: 8px;
    }

    &:nth-of-type(3) {
      top: 16px;
    }
  }
}

.overlay {
  position: fixed;
  z-index: 100;
  background: $background-white;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  opacity: 0;
  visibility: hidden;
  transition: opacity .35s, visibility .35s, height .35s;
  overflow: hidden;

  &.open {
    opacity: 1;
    visibility: visible;
    height: 100%;

    li {
      animation: fadeInRight .5s ease forwards;
      animation-delay: .35s;

      &:nth-of-type(2) {
        animation-delay: .4s;
      }
      &:nth-of-type(3) {
        animation-delay: .45s;
      }
      &:nth-of-type(4) {
        animation-delay: .5s;
      }
      &:nth-of-type(5) {
        animation-delay: .55s;
      }
      &:nth-of-type(6) {
        animation-delay: .6s;
      }
      &:nth-of-type(7) {
        animation-delay: .65s;
      }
    }
  }
  nav {
    position: relative;
    height: 70%;
    top: 50%;
    transform: translateY(-50%);
    font-size: 50px;
    font-weight: 400;
    text-align: center;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    position: relative;
    height: 100%;

    li {
      display: block;
      position: relative;
      opacity: 0;
      .number{
        display: none;
        background-color: $background-yellow;
        border-radius: 50%;
        padding: 1px 5px;
        font-weight: 600;
        margin-left: 5px;
        font-size: 16px;
        position: relative;
        top: -3px;
      }

      a {
        display: inline-block;
        position: relative;
        color: $text-color-light;
        text-decoration: none;
        font-weight: 500;
        line-height: normal;
        font-size: 26px;

        &:hover:after,
        &:focus:after,
        &:active:after,
        &.active:after {
          width: 100%;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: -10px;
          left: 50%;
          width: 0%;
          transform: translateX(-50%);
          height: 4px;
          background: $background-yellow;
          transition: .35s;
        }
      }
    }
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

// media
@media screen and (max-width: 767px){
  .overlay ul li a{
    font-size: 20px;
  }
}