.bottom_block{
  background: $background-light-grey;
  margin-top: 140px;
  padding-bottom: 140px;
  position: relative;
  &:before{
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    top: -140px;
    height: 140px;
    width: 100%;
    background: url("../../img/bottom-block-top.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }
  &__sub_title{
    margin-top: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
    font-size: 18px;
    text-align: center;
    color: $text-color-light;
  }
}

// media
@media screen and (max-width: 1024px){
  .bottom_block{
    margin-top: 0;
    padding-top: 80px;
    padding-bottom: 60px;
    &:before{
      content: none;
    }
    &__sub_title{
      line-height: 27px;
      font-size: 15px;
    }
  }
}
@media screen and (max-width: 767px) {
  .bottom_block{
    padding-top: 35px;
    padding-bottom: 35px;
  }
}
