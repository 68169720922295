.company{
  .information {
    margin-top: 280px;
    padding-bottom: 90px;
    background-color: $background-light-grey;
    position: relative;
    &:before{
      content: "";
      display: inline-block;
      position: absolute;
      top: -140px;
      left: 0;
      width: 100%;
      height: 140px;
      background: url("../img/company-information-top.svg");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

// media
@media screen and (max-width: 1024px){
  .company{
    .information {
      margin-top: 0;
      &:before {
        content: none;
      }
      }
  }
}