.graphics{
  .tab-content{
    overflow: hidden;
  }
  padding-top: 90px;
  padding-bottom: 130px;
  &__title{
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-size: 36px;
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
    color: $text-color-bold;
  }
  &__sub_title{
    margin: 50px auto;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
    font-size: 18px;
    text-align: center;
    color: $text-color-light;
  }
}

// media
@media screen and (max-width: 1024px) {
  .graphics{
    padding-top: 70px;
    padding-bottom: 80px;
    &__title{
      font-size: 26px;
    }
    &__sub_title{
      line-height: 27px;
      font-size: 15px;
    }
  }
}
@media screen and (max-width: 767px) {
  .graphics{
    .card{
      margin-bottom: 20px;
      .mobile_nav{
        margin-bottom: 10px;
      }
    }
    padding-top: 40px;
    padding-bottom: 40px;
    &__title{
      font-size: 20px;
    }
    &__sub_title{
      margin: 25px auto;
    }
  }
}

