.contacts{
  padding-top: 90px;
  padding-bottom: 75px;
  .item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__title{
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      font-size: 20px;
      text-align: center;
      color: $text-color-bold;
      margin: 30px 0 20px;
    }
    &__description{
      font-style: normal;
      text-align: center;
      font-weight: 300;
      line-height: normal;
      font-size: 15px;
      .small{
        color: $text-color-light;
        font-weight: 300;
        line-height: 27px;
        font-size: 15px;
        max-width: 270px;
      }
      a{
        transition: .35s;
        color: $text-color-bold;
        &:hover{
          color: $text-color-blue;
        }
      }
    }
  }
}

// media
@media screen and (max-width: 1024px){
  .contacts{
    padding-top: 60px;
    padding-bottom: 45px;
    .item {
      &__title {
        font-size: 18px;
        margin: 20px 0 15px;
      }
      &__description {
        font-size: 18px;
      }
      &__image{
        img{
          max-width: 60px;
        }
      }
    }
  }
}
@media screen and (max-width: 991px){
  .contacts{
    padding-bottom: 10px;
    .item {
      margin-bottom: 35px;
    }
  }
}
@media screen and (max-width: 767px){
  .contacts{
    padding-top: 40px;
    padding-bottom: 20px;
    .item{
      &__image{
        img{
          max-width: 50px;
        }
      }
    }
  }
}